import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import { HomeLayout } from './home/_Home';

const indexHome = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || 'Title';
  return (
    <Layout location={location} title={siteTitle}>
      <HomeLayout />
    </Layout>
  );
};

indexHome.propTypes = {
  data: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
};

export default indexHome;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`;
